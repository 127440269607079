import React, { useEffect, useRef, useState } from 'react';

type LazyImageProps = {
  src: string;
  alt: string;
  className?: string;
};

const LazyImage: React.FC<LazyImageProps> = ({ src, alt, className }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { rootMargin: '100px', threshold: 0.1 }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      observer.disconnect();
      //if (imgRef.current) {
      //  observer.unobserve(imgRef.current);
      //}
    };
  }, []);

  return <img ref={imgRef} src={isVisible ? src : ''} alt={alt} style={{ visibility: isVisible ? 'visible' : 'hidden' }} />;
};

export default LazyImage;
