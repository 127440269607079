import React from 'react';

import { AddressContainer, AddressMapInfoContainer } from './Address.styles';

import { ReactComponent as Phone } from '@/assets/img/icon/phone.svg';
import KakaoMap from '@/components/KakaoMap/KakaoMap';
import Modal, { Menu } from '@/components/Modal/Modal';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import { callService } from '@/hooks/callService';
import { useModalStore } from '@/stores/common/useModalStore';
import { useBookingStore } from '@/stores/useBookingStore';

const Address = ({ store }): React.ReactElement => {
  const { storeOpenTimes } = useBookingStore().mall;
  // 영업시간과 휴무일 정보를 분리
  const openTimeInfo = storeOpenTimes
    .filter((item) => item.type !== 'CLOSE')
    .map(
      (item) =>
        `${item.target} ${Math.floor(item.start / 60)
          .toString()
          .padStart(2, '0')}:${(item.start % 60).toString().padStart(2, '0')}~${Math.floor(item.end / 60)
          .toString()
          .padStart(2, '0')}:${(item.end % 60).toString().padStart(2, '0')}${item.etc ? ` (${item.etc})` : ''}`
    )
    .join(',\n');
  const { setAlertModal, toastList, setToastList, setOpenGlobalErrorModal, setOpenGlobalConfirmModal } = useModalStore();

  const closedInfo = storeOpenTimes
    .filter((item) => item.type === 'CLOSE')
    .map((item) => `${item.target} 휴무`)
    .join(', ');

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(store.address);
      setToastList([...toastList, { visible: true, key: 'handleCopy' }]);
    } catch (err) {
      // console.error("복사 실패:", err);
      // alert("복사에 실패했습니다.");
    }
  };

  return (
    <>
      <AddressContainer>
        <h1>매장 정보</h1>
        <KakaoMap lat={store.latitude} lng={store.longitude} address={store.address} />
        <AddressMapInfoContainer>
          <div className="info-item">
            <span>주소</span>
            <span>
              {store.address}
              <br></br>({store.pastAddress}) &nbsp;
              <span className="tag-xs" onClick={handleCopy}>
                복사
              </span>
            </span>
          </div>
          <div className="info-item">
            <span>전화</span>
            <span>{store.phone}</span>
          </div>
          <div className="info-item">
            <span>영업시간</span>
            <span>{openTimeInfo}</span>
          </div>
          {closedInfo ? (
            <>
              <div className="info-item">
                <span>휴무일</span>
                <span>{closedInfo}</span>
              </div>
            </>
          ) : null}
        </AddressMapInfoContainer>
      </AddressContainer>
      <ToastPortal>
        <Modal.Toast key="handleCopy" toastKey="handleCopy" modal="toast" autoHideDuration={2000} isAnimation={true} position="bottom">
          <div>주소가 복사되었습니다.</div>
        </Modal.Toast>
      </ToastPortal>
    </>
  );
};

export default Address;
