import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const MainMallContainer = styled.div<React.CSSProperties>`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  display: flex;
  height: 343px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 170px;
  flex: 1 0 0;
  /*border-radius: 6px;*/
  /* border: 1px solid var(--white-10, rgba(255, 255, 255, 0.10)); */

  /*.mall-items {*/
  position: relative;
  width: 100%;
  /*height: 100%;*/

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-area {
    position: absolute;
    bottom: 0;
    display: flex;
    /* width: fit-content; */
    width: 100%;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 16px;
    background: var(--gray-70040, rgba(59, 59, 57, 0.6));
    /*backdrop-filter: blur(10px);*/
    /*border-radius: 0 0 6px 6px;*/
    .title-area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      h2 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        align-self: stretch;
        overflow: hidden;
        color: var(--white, #fff);
        text-overflow: ellipsis;

        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
      }
      .desc {
        display: flex;
        align-items: center;
        gap: 8px;
        /* opacity: 0.8; */
        color: rgba(255, 255, 255, 0.8);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }
    }
  }
  /*}*/

  /* shadow-product */
  /*box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.4), 0px 4px 16px 0px rgba(0, 0, 0, 0.2);*/

  .tag-area {
    display: flex;
    padding: 6px 12px 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  & > a {
    .mall-img-wrapper {
      /* shadow-product */
      height: 343px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .blur-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140px;
        overflow: hidden;
        background: rgba(59, 59, 57, 0.4);
      }

      .blur-background {
        position: inherit;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 333.33%; /* 100% / 30% to cover the entire image */
        background-image: inherit;
        background-position: center;
        background-size: cover;
        backdrop-filter: blur(20px);
      }
      .overlay-contents {
        position: inherit;
        color: white;
        /* text-align: center; */
        padding: 28px 20px;
        z-index: 1;
        width: 100%;

        h2 {
          margin: 0 0 20px;
          font-size: 36px;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 100%;
        }
        p {
          margin: 0;
          font-size: 24px;
          font-weight: 100;
        }
      }
    }
    & > h2 {
      max-width: 100%;
      margin-bottom: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${Typography.body_28_sb}
    }
    & > span {
      display: block;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      ${Typography.small_1}
      color:${Colors.gray6}
    }
  }
`;
export const MainMallFullBadge = styled.div<React.CSSProperties>`
  position: absolute;
  top: 0;
  left: 0;
  &::after {
    content: 'SOLD OUT';
  }
  display: flex;
  padding: 6px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /*border-radius: 6px 0px 6px 0px;*/
  /* border-radius: 0 0 6px 6px; */
  background: ${Colors.gray11};

  color: ${Colors.white};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const MainMallComingSoonBadge = styled.div<React.CSSProperties>`
  position: absolute;
  top: 0;
  left: 0;
  &::after {
    content: 'BE READY';
  }
  display: flex;
  padding: 6px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /*border-radius: 6px 0px 6px 0px;*/
  background: ${Colors.white};

  color: ${Colors.gray11};
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;

export const MainMallNewBadge = styled.div<React.CSSProperties>`
  position: absolute;
  top: 0;
  left: 0;
  &::after {
    content: 'COMING\\ASOON';
    white-space: pre-wrap;
    text-align: center;
  }
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 12px;
  /*border-radius: 12px;*/
  color: ${Colors.white};
  ${Typography.body_28_m};
  width: 100%;
  height: 100%;
  background: rgba(39, 42, 45, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainMallLiveBadge = styled.div<React.CSSProperties>`
  position: absolute;
  top: 0;
  left: 0;
  &::after {
    content: 'LIVE';
  }
  display: flex;
  padding: 6px 12px 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /*border-radius: 6px 0px 6px 0px;*/
  background: var(--primary-dc-light-green, #41996a);

  color: var(--label, #fff);
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
`;
