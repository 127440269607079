import 'dayjs/locale/ko';

import React, { Fragment, useEffect, useId, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import { ReactComponent as Phone } from '@/assets/img/icon/phone.svg';
import { AddressMapInfoContainer } from '@/components/Address/Address.styles';
import Button from '@/components/Button/Button';
import { HistoryDetailContainer, ReservationDetailContainer, ReservationDetailItem } from '@/components/HistoryDetail/HistoryDetail.styles';
import KakaoMap from '@/components/KakaoMap/KakaoMap';
import Modal from '@/components/Modal/Modal';
import ModalPortal from '@/components/ModalPortal/ModalPortal';
import RefundPolicy from '@/components/RefundPolicy/RefundPolicy';
import ToastPortal from '@/components/ToastPortal/ToastPortal';
import { callService } from '@/hooks/callService';
import { deleteReservationData, filterShowPartyDetailPeople, postReservationCancel, useReservationHistoryDetailData } from '@/hooks/reservationHistory.hook';
import { RESERVATION_HISTORY_STATUS } from '@/shared/constants/reservation-history.constants';
import { hypenPhoneNum } from '@/shared/libs/function/auto-hypen';
import { copyMessage, makeShareText } from '@/shared/libs/function/shreText';
import { AddComma } from '@/shared/libs/functions';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

dayjs.locale('ko');

interface IRefundData {
  depositPrice: number;
  refundPercent: number;
  refundPrice: number;
  token: string;
}

const HistoryDetail = (): React.ReactElement => {
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const navigate = useNavigate();
  const { toastList, setToastList, setAlertModal } = useModalStore();
  const { reservationId } = useParams();
  const [refundData, setRefundData] = useState<IRefundData>();
  const [isSuccessCancelReservation, setIsSuccessCancelReservation] = useState(false);
  const mainRef = useRef(null);

  const shareReservationHistoryKey = useId();
  const successCopyTextKey = useId();
  const cancelInfoAmountKey = useId();
  const cancelInfoKey = useId();

  const { data: historyDetailData, isLoading } = useReservationHistoryDetailData(reservationId);
  const { mutate: refundInfoResponse, isLoading: refundInfoResponseIsLoading } = postReservationCancel(reservationId, setRefundData);
  const { mutateAsync: submitCancelReservationMutate, isLoading: submitCancelIsLoading } = deleteReservationData(reservationId, setIsSuccessCancelReservation);

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
  }, []);

  useEffect(() => {
    setHeaderState({
      title: RESERVATION_HISTORY_STATUS[historyDetailData?.reservation?.state],
      handleGoToBack: () => navigate('/reservation/history', { replace: true }),
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: historyDetailData?.reservation?.state === 2 ? null : () => setAlertModal({ visible: true, key: shareReservationHistoryKey }),
    });
  }, [historyDetailData, reservationId]);

  const scrollTopWhenUserCancelReservation = () => {
    if (!mainRef.current.scrollTop) {
      return;
    }

    mainRef.current.scrollTo(0, 0);
  };

  if (isLoading) {
    return;
  }
  const { reservation, store, visitor } = historyDetailData;

  const CancelReservationHandler = () => {
    refundInfoResponse();
    // post
    if (reservation.paymentAmount) {
      setAlertModal({ visible: true, key: cancelInfoAmountKey });
    } else {
      setAlertModal({ visible: true, key: cancelInfoKey });
    }
  };

  const returnSubheadingStatusOfVisit = (state: number, visitAt: string) => {
    let content = null;
    if (state === 0) {
      const today = dayjs(Date.now()).format('YYYY-MM-DD');
      const visitDiff = dayjs(today).diff(visitAt, 'day') === 0 ? 'D-DAY' : `D${dayjs(today).diff(visitAt, 'day')}`;
      content = <h4 className="reserve-diff">{visitDiff}</h4>;
    } else if (state === 1) {
      content = <span className="visit-completed">방문완료</span>;
      //content = '방문완료된 예약입니다.';
    } else if (state === 2) {
      content = '방문취소된 예약입니다.';
      content = <span className="reserve-stts">취소</span>;
    } else if (state === 4) {
      content = 'No Show 예약입니다.';
      content = <span className="reserve-stts">노쇼</span>;
    } else if (isSuccessCancelReservation) {
      //content = '예약이 취소되었습니다.';
      content = <span className={isSuccessCancelReservation ? 'successCancel' : null}>예약이 취소되었습니다.</span>;
    }

    //content = <span className='successCancel'>예약이 취소되었습니다.</span>;

    return content === null ? null : content;
  };

  const submitCancelReservation = async () => {
    await submitCancelReservationMutate(refundData.token);
    if (reservation.paymentAmount) {
      setAlertModal({ visible: false, key: cancelInfoAmountKey });
    } else {
      setAlertModal({ visible: false, key: cancelInfoKey });
    }

    //scrollTopWhenUserCancelReservation();
    //self.location.reload();
    self.location.href = '/reservation/history/' + reservationId;
  };

  makeShareText({ reservation, store, visitor });

  return (
    <Fragment>
      <HistoryDetailContainer ref={mainRef}>
        {returnSubheadingStatusOfVisit(reservation.state, reservation.visitAt)}
        <h1>{store.name}</h1>
        <h2>예약 내용 확인</h2>
        <ReservationDetailContainer>
          <ReservationDetailItem>
            <span>예약일시</span>
            <p>{dayjs(reservation.visitAt).format('YYYY년 MM월 DD일 (dd) ∙ A h:mm')}</p>
          </ReservationDetailItem>
          <ReservationDetailItem>
            <span>예약인원</span>
            <p>
              {reservation.totalPartySize}명 ({filterShowPartyDetailPeople(reservation.partyDetail)})
            </p>
          </ReservationDetailItem>
          {reservation.paymentAmount ? (
            <ReservationDetailItem>
              <span>예약금액</span>
              <p>{AddComma(reservation.paymentAmount)}원</p>
            </ReservationDetailItem>
          ) : null}
        </ReservationDetailContainer>
        <h2>예약자 정보</h2>
        <ReservationDetailContainer>
          <ReservationDetailItem>
            <span>예약자</span>
            <p>{visitor.name}</p>
          </ReservationDetailItem>
          <ReservationDetailItem>
            <span>연락처</span>
            <p>{hypenPhoneNum(visitor.phone, 'hide')}</p>
          </ReservationDetailItem>
          <ReservationDetailItem>
            <span>요청사항</span>
            <p>{reservation.request?.length ? reservation.request : '없음'}</p>
          </ReservationDetailItem>
        </ReservationDetailContainer>
        <h2>매장 정보</h2>
        <KakaoMap className="kakao-address" lat={store.latitude} lng={store.longitude} address={store.address} />
        <AddressMapInfoContainer>
          <div className="info-item">
            <span>주소</span>
            <span>{store.pastAddress}</span>
          </div>
          {/*
          <h2>{store.address}</h2>
          <span>지번 : {store.pastAddress}</span>
          <div className="phone-box" onClick={() => callService(store.phone)}>
            <Phone />
            <span className="phone-number">{store.phone}</span>
          </div>
          */}
          <div className="info-item">
            <span>전화</span>
            <span onClick={() => callService(store.phone)}>{store.phone}</span>
          </div>
        </AddressMapInfoContainer>
        {store.depositRefunds?.length ? (
          <>
            <h2 style={{ paddingBottom: '40px' }}>취소 수수료 안내</h2>
            <RefundPolicy policies={store.depositRefunds} />
          </>
        ) : null}
        {reservation.state === 0 && !isSuccessCancelReservation ? (
          <Button
            colorLevel="secondary"
            type="large100"
            onClick={() => (reservation.state === 0 && !isSuccessCancelReservation ? CancelReservationHandler() : navigate('/reservation/history', { replace: true }))}
          >
            예약 취소
          </Button>
        ) : (
          ''
        )}
      </HistoryDetailContainer>
      <ModalPortal>
        {/* 공유하기 기능 */}
        <Modal.Alert key={shareReservationHistoryKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper">
            <h2 className="title">공유하기</h2>
            <p className="desc">예약 내역을 공유하시겠습니까?</p>
          </section>
          <section className="button-wrapper">
            <button className="white" onClick={() => setAlertModal({ visible: false, key: shareReservationHistoryKey })}>
              취소
            </button>
            <button
              onClick={() => {
                setAlertModal({ visible: false, key: shareReservationHistoryKey });
                copyMessage();
                setToastList([...toastList, { visible: true, key: successCopyTextKey }]);
              }}
            >
              내용 복사
            </button>
          </section>
        </Modal.Alert>
        {/* 예약금 O */}
        <Modal.Alert key={cancelInfoAmountKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="header-wrapper">
            <span>예약취소 안내</span>
          </section>
          <section className="content-wrapper lots-of-content-wrapper cancel-reservation">
            {reservation.type === '01'
              ? '취소한 예약은 복구할 수 없으며, 결제하신 예약금은 취소 수수료 규정에 따라 환불됩니다.'
              : '해당 예약은 익스프레스 예약입니다. 같은 매장 내 익스프레스 예약은 월 2회만 가능하며 예약을 취소해도 사용 횟수는 복구되지 않습니다.'}
            {/* <h2 className="title">예약 취소 안내</h2> */}
            <section className="desc">
              <div className="refund-table">
                <h4>환불 안내</h4>
                <div className="desc-item">
                  <span className="title">예약금</span>
                  <span className="price">{AddComma(reservation.paymentAmount)}원</span>
                </div>
                <div className="desc-item">
                  <span className="title">취소 수수료</span>
                  <span className="price">{AddComma(reservation.paymentAmount - refundData?.refundPrice)}원</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" height="2" viewBox="0 0 263 1" fill="none">
                  <path d="M0 1V0H263V1H0Z" fill="white" />
                </svg>
                <div className="total-wrapper">
                  <em>환불 받을 금액</em>
                  <span>{AddComma(refundData?.refundPrice)}원</span>
                </div>
              </div>
            </section>
          </section>
          <section className="button-wrapper cancel-reservation">
            <button className="white" onClick={() => setAlertModal({ visible: false, key: cancelInfoAmountKey })}>
              닫기
            </button>
            <button onClick={submitCancelReservation}>예약취소</button>
          </section>
        </Modal.Alert>
        {/* 예약금 X */}
        <Modal.Alert key={cancelInfoKey} modal="alert" isDim={true} isAnimation={true}>
          <section className="content-wrapper cancel-reservation2">
            {reservation.type === '01'
              ? '예약을 취소하시겠습니까?'
              : '해당 예약은 익스프레스 예약입니다. 같은 매장 내 익스프레스 예약은 월 2회만 가능하며 예약을 취소해도 사용 횟수는 복구되지 않습니다.'}
          </section>
          <section className="button-wrapper">
            <button className="white" onClick={() => setAlertModal({ visible: false, key: cancelInfoKey })}>
              닫기
            </button>
            <button className="green" onClick={submitCancelReservation}>
              예약취소
            </button>
          </section>
        </Modal.Alert>
      </ModalPortal>
      <ToastPortal>
        <Modal.Toast key={successCopyTextKey} toastKey={successCopyTextKey} modal="toast" autoHideDuration={3000} isAnimation={true} position="bottom">
          <div>텍스트가 복사되었습니다</div>
        </Modal.Toast>
      </ToastPortal>
    </Fragment>
  );
};

export default HistoryDetail;
