/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
//import { Map, MapMarker } from 'react-kakao-maps-sdk';

import { IKakaoMap } from './KakaoMap.types';

import { KakaoMapContainer } from '@/components/KakaoMap/KakaoMap.styles';

// address 필요 없음
const KakaoMap = ({ lat, lng, className, address }: IKakaoMap): React.ReactElement => {
  // return (
  //   <Map center={{ lat: lat, lng: lng }} style={{ width: '100%', height: '23vh', borderRadius: '6px' }} level={1} zoomable={true} draggable={false}>
  //     <MapMarker position={{ lat: lat, lng: lng }}>{/* {<div style={{ color:'#000' }}>Hello World!</div>} */}</MapMarker>
  //   </Map>
  // );
  const mapRef = useRef(null);
  const mapInstanceRef = useRef<any>(null);

  function mapClickEvent() {
    // const latlng = [lat, lng].join();
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   window.location.href = `kakaomap://look?p=${latlng}`;
    // } else {
    //   window.open(`https://map.kakao.com/link/to/${latlng}`, '_blank');
    // }
  }

  useEffect(() => {
    let mounted = true;

    const initializeMap = () => {
      if (!mounted || !mapRef.current || typeof kakao === 'undefined' || !lat || !lng || (lat === 0 && lng === 0)) {
        return;
      }

      console.log('Initializing map with:', lat, lng);

      const staticMapOption = {
        center: new kakao.maps.LatLng(lat, lng),
        level: 1,
        marker: {
          position: new kakao.maps.LatLng(lat, lng),
        },
      };

      // 새로운 지도 생성 전에 이전 요소 초기화
      if (mapRef.current) {
        mapRef.current.innerHTML = '';
      }

      // 새로운 지도 인스턴스 생성
      mapInstanceRef.current = new kakao.maps.StaticMap(mapRef.current, staticMapOption);
    };

    // kakao maps API가 로드된 경우에만 실행
    if (typeof kakao !== 'undefined' && kakao.maps) {
      kakao.maps.load(initializeMap);
    }

    return () => {
      mounted = false;
      // 컴포넌트 언마운트 시 DOM 요소 정리
      if (mapRef.current) {
        mapRef.current.innerHTML = '';
      }
      mapInstanceRef.current = null;
    };
  }, [lat, lng]);

  return (
    <div onClick={mapClickEvent}>
      <KakaoMapContainer className={className ?? null} ref={mapRef} />
    </div>
  );
};

export default KakaoMap;
