import React from 'react';

import styled from '@emotion/styled';

import { Colors } from '../../colors';

//import LoginBgImage from '@/assets/img/f338391c4436ac62afad529315ae626c.jpg';
import Applelogo from '@/assets/img/icon/applelogo.svg';
import Googlelogo from '@/assets/img/icon/googlelogo.svg';
import Kakaoballon from '@/assets/img/icon/kakaoballoon.svg';
import LoginBgImage from '@/assets/img/login-bg_new3.png';
import { Typography } from '@/shared/styles/typography';

export const SignInPageContainer = styled.section<React.CSSProperties>`

  //display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: flex-start !important;
  width: 100%;
  height: 100%;
  transition: 1s background-color;
  flex-shrink: 0;
  &.yellowbg {
    background-color: #f2c74a;
    svg.egglogomain {
      .yellow-dot {
        transition: fill 1s;
        fill: #231815;
      }
    }
  }
  .splash-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: opacity 1s ease-out; 
    opacity: 1; 

    .logo-text {
      color: ${Colors.white}
      text-align: center;
      font-family: 'Pretendard', sans-serif;
      font-size: 80px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 40px */
      letter-spacing: -0.8px;
    }
  }
  .siginin-wrapper {
    /* background: linear-gradient(180deg, rgba(59, 59, 57, 0.00) 0%, #3B3B39 60%); */
    /* background: linear-gradient(0deg, rgba(59, 59, 57, 0.00) 0%, #3B3B39 100%); */

    /* opacity: 0.3; */
    /* background: var(--gray-700, #3B3B39); */

    /* background: url(${LoginBgImage}) lightgray 50% / cover no-repeat; */
    background: url(${LoginBgImage}) #3B3B39 50% / cover no-repeat;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  svg.egglogomain {
    transition: 1s all;
    margin-bottom: 0;
    .yellow-dot {
      fill: #ffc600;
    }
  }

  .logomain {
    color: #fff;
    text-align: center;
    font-family: pretendard, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 40px */
    letter-spacing: -0.8px;
  }

  .siginin-main-bg {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(59, 59, 57, 0) 0%, #3b3b39 90%);
    display: flex;
    text-align: center;
  }
  .signin-top-area {
    background: linear-gradient(180deg, rgba(59, 59, 57, 0) 0%, #3b3b39 90%);
    display: flex;
    width: 100%;
    height: 80%;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
  }
  .siginin-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;
    align-self: flex-end;
    text-align: center;
    gap: 32px;

    & > svg {
      width: 400px;
      height: 70px
    }
  }
  .siginin-logo-title {
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: pretendard, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 40px */
    letter-spacing: -0.8px;
    margin-bottom: 24px;
  }
  .signin-logo-desc {
    width: 100%;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;

    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    padding-bottom: 80px;
  }
  section.signin-button-wrapper {
    font-family: 'Pretendard';
    opacity: 1;
    overflow: hidden;
    display: flex;
    align-items: center;
    transition: 1s all, 1s opacity 1s;
    flex-direction: column;
    gap: 20px;
    pointer-events: none;
    margin-bottom: 160px;
    //position: absolute;
    & > button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 26px 0;
      gap: 12px;
      border-radius: 3px;
      font-weight: 500;
      font-style : normal;
      font-size: 32px;
      line-height: 42px;
      /*letter-spacing: 0;*/
      width: 656px;
      &.signin-with-kakao {
        background: #FEE500;
        color: #191600;
        /*
        &:before {
          display: block;
          content: '';
          width: 48px;
          height: 48px;
          background-size: 100%;
          background-image: url(${Kakaoballon});
          color: #34201c;
        }
          */
      }
      &.signin-with-apple {
        background: var(--gray-900, #14120E);
        color: #fff;
        /*
        &:before {
          display: block;
          content: '';
          width: 48px;
          height: 48px;
          background-size: 100%;
          background-image: url(${Applelogo});
        }
          */
      }
      &.signin-with-google {
        color: rgb(39, 42, 45);
        background-color: #ffffff;
        &:before {
          display: block;
          content: '';
          width: 48px;
          height: 48px;
          background-size: 100%;
          background-image: url(${Googlelogo});
        }
      }
      &.none-login-button {
        ${Typography.small_1};
        font-family : "Pretendard";
        opacity : 0.8;
        color: #fff;
        gap: 0;
      }
    }
  }
  &.siginin-loaded {
    svg.egglogomain {
      margin-bottom: 680px;
      .yellow-dot {
        fill: #ffc600;
      }
    }
    section.signin-button-wrapper {
      pointer-events: unset;
      opacity: 1;
    }
  }
`;
