import React from 'react';

import styled from '@emotion/styled';

import { Colors, Typography } from '@/shared/styles';

export const AddressContainer = styled.section<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  & > h1 {
    ${Typography.title_36_sb};
    color: ${Colors.white};
    /* padding-bottom: 12px; */
  }
  .phone-box {
    display: flex;
    align-items: center;
    margin-top: 16px;
    .phone-number {
      ${Typography.body_28}
      color: ${Colors.blue}
    }
  }
`;

export const AddressMapInfoContainer = styled.div<React.CSSProperties>`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 24px;
  /* opacity: 0.8; */

  .info-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: stretch;
    justify-content: flex-start;
    gap: 16px;
    ${Typography.body_28_ss}

    & > span {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      white-space: pre-line;

      &:first-of-type {
        width: 160px;
        ${Typography.body_28_s}
      }

      &:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
      }

      .tag-xs {
        display: inline-flex;
        padding: 2px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        background: var(--white-10, rgba(255, 255, 255, 0.1));

        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }
    }
  }

  /* & > span {
    ${Typography.body_28_ss}
    color: ${Colors.white}
  }

  & > p {
    ${Typography.body_28};
    color: #fff;
  }

  span:nth-child(2n-1) {
    width: 120px;
  }

  span:nth-child(2n) {
    flex: 1;
    margin-right: auto;
    min-width: 400px;
  } */
`;
