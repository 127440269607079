import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@/components/Button/Button';
import { ButtonWrapper } from '@/components/CardPaymentInfo/CardPaymentInfo.styles';
import MembershipcancelSuccess from '@/components/MembershipcancelSuccess/MembershipcancelSuccess';
import { useFooterStore } from '@/stores/common/useFooterStore';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useNavigationStore } from '@/stores/common/useNavigationStore';

const CancelSuccess = (): React.ReactElement => {
  const setIsFooterVisible = useFooterStore((store) => store.setIsFooterVisible);
  const setIsNavigationVisible = useNavigationStore((store) => store.setIsNavigationVisible);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const navigate = useNavigate();

  useEffect(() => {
    setIsFooterVisible(false);
    setIsNavigationVisible(false);
    setHeaderState({
      title: '멤버십 해지',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: () => navigate('/mypage/membership'),
      handleOnShare: null,
    });
  }, []);
  return (
    <>
      <MembershipcancelSuccess />
      <div className="bottom-rayout">
        <ButtonWrapper>
          <Link to="/main">
            <Button colorLevel="primary" type="sticky">
              클럽 하우스 메인으로 이동
            </Button>
          </Link>
        </ButtonWrapper>
      </div>
    </>
  );
};

export default CancelSuccess;
