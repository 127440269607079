import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { throttle } from 'lodash';

import Button from '../Button/Button';
import { IMainHeader } from './MainHeader.types';

import { ReactComponent as DiningClubLogo } from '@/assets/img/diningclub_logo_small.svg';
import { ReactComponent as EggLogo } from '@/assets/img/icon/egg_logo.svg';
import { ReactComponent as SmallEggLogo } from '@/assets/img/icon/egg_logo_small.svg';
import { MainHeaderContainer, SmallMainHeaderContainer } from '@/components/MainHeader/MainHeader.styles';
import { LocalStorage } from '@/shared/configs/storage';
import { useHeaderStore } from '@/stores/common/useHeaderStore';
import { useModalStore } from '@/stores/common/useModalStore';

const MainHeader = ({ containerRef }: IMainHeader): React.ReactElement => {
  const [isScrollMove, setIsScrollMove] = useState(0);
  const setHeaderState = useHeaderStore((store) => store.setHeaderState);
  const { setOpenGlobalConfirmModal } = useModalStore();
  const navigate = useNavigate();

  const moveToHistory = () => {
    if (LocalStorage.getItem('loginType') === 'none') {
      setOpenGlobalConfirmModal({
        visible: true,
        title: `다이닝 클럽 멤버를 위한 공간입니다.
        로그인 후 사용 가능합니다.`,
        message: '',
        closeText: '닫기',
        okayText: '로그인',
        callback: () => {
          navigate('/auth/signin');
        },
      });
    } else {
      navigate('/reservation/history');
    }
  };

  //const handleScroll = throttle(() => {
  //  const position = Math.ceil(containerRef.current.scrollTop);
  //  setIsScrollMove(position);
  //}, 100);

  useEffect(() => {
    setHeaderState({
      title: '',
      handleGoToBack: null,
      handleGoToHome: null,
      handleOnAlarm: null,
      handleOnClose: null,
      handleOnShare: null,
    });
    //containerRef.current.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      //containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef]);
  return (
    <>
      <MainHeaderContainer scroll={isScrollMove}>
        {/* {<EggLogo />} */}
        <Button colorLevel="secondary" type="small" onClick={moveToHistory}>
          <span>예약내역</span>
        </Button>
      </MainHeaderContainer>
      <SmallMainHeaderContainer scroll={isScrollMove}>
        <DiningClubLogo />
        {/* <Button colorLevel="blank" type="zero-padding-small" onClick={moveToHistory}>
          <span>DiningClub</span>
        </Button> */}
        <Button colorLevel="secondary" type="small" onClick={moveToHistory}>
          <span>예약내역</span>
        </Button>
      </SmallMainHeaderContainer>
    </>
  );
};

export default MainHeader;
