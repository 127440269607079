import React from 'react';

import styled from '@emotion/styled';

import { Typography, Colors } from '@/shared/styles';

export const CalendarContainer = styled.div<React.CSSProperties>`
  background: ${Colors.gray11_opacity40};
  margin: 32px 0;
  padding: 48px 32px;
  border-radius: 6px;
  /* gap: 24px; */
  .month-handler {
    display: flex;
    align-items: center;
    /* align-self: stretch; */
    justify-content: space-between;
    padding-bottom: 24px;
    /* padding: 0 32px 28px; */
    .date-text {
      /* font-weight: 600; */
      ${Typography.body_28}/* line-height: 72px; */
    }

    .action-container {
      display: flex;

      .prev-button {
        margin-right: 40px;
      }

      .icon {
        width: 40px;
        height: 40px;
        vertical-align: middle;

        &.disabled {
          opacity: 20%;
        }
      }
    }
  }

  .picker-container {
    position: relative;
    .month-container {
      /* padding: 0 11px; */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .week-list {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        /* display: grid;
        grid-template-columns: repeat(7, auto);
        justify-content: space-between; */
        &:first-of-type {
          opacity: 0.6;
        }
        .day {
          position: relative;
          display: flex;
          height: 80px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
          ${Typography.body_28};
          color: ${Colors.white};

          /* *.text {
            color: #e9ebee;
          } */

          &.deactive {
            opacity: 0.2;
            /* color: rgba(51, 51, 51, 0.15) !important; */
          }

          &.selected {
            /* color: ${Colors.gray11};
            background: ${Colors.white};
            border-radius: 6px;
            height: 64px;
            margin: 8px 0; */
            /* margin: 0; */
            /* border: 2px solid #f6c944; */
            /* background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #f6c944; */
            /* border-radius: 0.5rem; */
            /* color: #272a2d; */

            & > .date {
              color: #14120e;
              background: ${Colors.white};
              border-radius: 6px;
              padding: 0 20px;
              height: 64px;
              width: 75%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              /* height: 64px; */
              /* margin: 8px 0; */
            }
          }

          &.sunday {
            /* color: #ff471e; */
          }

          &.saturday {
            /* color: #2e81f8; */
          }
        }
      }
    }
  }
`;

export const ExpressPeriodContainer = styled.div<React.CSSProperties>`
  /* padding: 0 11px; */
  top: 0;
  left: 0;
  width: 100%;
  .week-list {
    display: flex;
    align-items: center;
    align-self: stretch;
    .day {
      width: 100%;
      height: 80px;
      /* @media (min-width: 640px) and (max-width: 719px) {
        height: 80px;
      } */
      &.express {
        /* border-radius: 3px; */
        display: flex;
        flex-direction: row;
        justify-content: center;

        background: var(--secondary-50010, rgba(221, 197, 164, 0.1));
        height: 64px;
        margin: 8px 0;
        /* background: ${Colors.blue};
        opacity: 0.2; */
        &.first {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &.last {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
        & > .select-date {
          width: 75%;
          height: 64px;
          border-radius: 6px;
          background: ${Colors.white};
        }
      }
    }
  }
`;

export const ExpressInfoText = styled.div<React.CSSProperties>`
  position: absolute;
  top: -25px;
  white-space: nowrap;
  ${Typography.small_1};
  color: ${Colors.beige};
`;
