import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocalStorage } from '@/shared/configs/storage';
import { Nullable } from '@/shared/types/common.types';
import { isApp } from '@/shared/utils';

const Social = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [socialType, setSociclType] = useState<Nullable<string>>();
  const [socialCode, setSocialCode] = useState<Nullable<string>>();

  const versionCheck = () => {
    const userAgent = window.navigator.userAgent.toLowerCase().split('/');
    console.log('versionCheck / ' + userAgent);
    const currentVersion = userAgent[userAgent.length - 1];
    const numericVersion = currentVersion.replace(/\./g, '');
    const integerVersion = parseInt(numericVersion, 10);
    const previousVersion = 200;

    console.log('versionCheck : ' + integerVersion);

    if (integerVersion > previousVersion) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const code = searchParams.get('code');
    const type = searchParams.get('type');

    if (!code || !type) {
      navigate('/auth/signin');
    } else {
      setSociclType(type);
      setSocialCode(code);
    }
  }, []);

  useEffect(() => {
    //const versionValidation = isApp() && versionCheck();
    const versionValidation = isApp();
    //const versionValidation = versionCheck();

    if (socialCode && socialType) {
      location.href = `${process.env.REACT_APP_API_HOST}eggdining/oauth/${socialType}/login?code=${socialCode}&oauth_channel=${versionValidation ? 'native' : 'web'}`;
    }
  }, [socialCode, socialType]);

  return <></>;
};

export default Social;
